export default {
  imageContainer: (title) => ({
    maxWidth: ['100vw', '100vw', '100vw'],
    width: '100%',
    height: ['', '360px'],
    aspectRatio: ['4 / 3', ''],
    mt: !title && ['', '30px'],
    borderRadius: ['0px', '8px'],
    overflow: 'hidden',
  }),
  image: {
    cursor: 'pointer',
    p: 0,
    width: ['100vw', '100vw', '896px'],
    objectFit: 'cover',
    aspectRatio: ['4 / 3', ''],
    height: ['', '360px'],
    borderRadius: ['0px', '8px'],
  },
  labelContainer: (isMobile) => ({
    px: !isMobile && '0px',
    maxWidth: ['100%', '100%'],
    width: '100%',
    alignItems: 'flex-end',
  })
}
