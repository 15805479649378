/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui'

import HomeComponent from '/components/HomeComponent'
import KoreaHomeComponent from '/components/Korea/HomeComponent'
import WithMainLayout from '/components/hocs/MainLayout'
import logger from '/infra/logger'
import { getEntry } from '/lib/contentful'
import utils from '/utils'

const Landing = (props) => {
  const { pageProps, isMobile } = props
  const { layout = {}, locale = 'en' } = pageProps

  if (locale === 'kr')
    return <KoreaHomeComponent {...pageProps} isMobile={isMobile} />

  return <HomeComponent contentHub={layout} />
}
export default WithMainLayout(Landing, true)

export const getServerSideProps = async ({ params, query }) => {
  const { locale } = params

  try {
    utils.locale.throwIfInvalidLocale(locale)
    let footer

    const [meta, sideMenu, laguageSwitch, contentHub] = await Promise.all([
      getEntry('meta', locale),
      getEntry('sidemenu_layout', locale),
      getEntry('language_switch', locale),
      getEntry('ch_layout', locale),
    ])

    if (locale === 'kr') {
      footer = await getEntry('my_page_footer', locale)
    }

    return {
      props: {
        locale,
        meta: meta || {},
        layout: contentHub || null,
        sideMenu: sideMenu || null,
        laguageSwitch: laguageSwitch || null,
        footer: footer || null,
        query,
      },
    }
  } catch (error) {
    logger.error(JSON.stringify(error || {}))
    return { props: {} }
  }
}
