import Box from "../../../Commons/Box"
import HomeCard from ".."

import styles from "./styles"

const RowAligned = ({ entryLeft, entryRight, locale, onTrackEvent, isMobile }) => {
  return (
    <Box sx={styles.container}>
      <Box sx={styles.elementContainer}>
        <HomeCard
          locale={locale}
          titleAligned
          {...entryLeft}
          onClickContent={() => onTrackEvent(entryLeft)}
          isMobile={isMobile}
        />
        {entryLeft.description && (
          <Box
            sx={styles.description}
          >
            {entryLeft.description}
          </Box>
        )}
      </Box>

      {entryRight && (
        <Box sx={styles.elementContainer}>
          <HomeCard
            locale={locale}
            titleAligned
            {...entryRight}
            onClickContent={() => onTrackEvent(entryRight)}
            isMobile={isMobile}
          />
          {entryRight.description && (
            <Box sx={styles.description}>
              {entryRight.description}
            </Box>
          )}
        </Box>
      )
      }
    </Box>
  )
}

export default RowAligned