export default {
  container: {
    backgroundColor: 'tmWhite',
    width: '100%',
    height: 'auto',
    padding: ['0px 0px 40px 0px', '30px 0'],
    color: 'tmBlue',
    border: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },

  paddingY: ({ images, text }) => ({
    pb: !images && '0',
    pt: !text && '0',
  }),

  multipleRowImages: {
    p: ['0 20px', '0px 20px', '0px'],
    display: 'flex',
    flexDirection: ['column', 'row'],
    justifyContent: 'space-between',
    width: '100%',
    '& > div': {
      p: ['0px 0px 40px 0px', '0px'],
      ml: ['0px', '16px', '16px'],
      '&:first-child': {
        m: ['0px', '0px'],
      },
      '&:last-child': {
        pb: ['0px'],
      },
    },
    span: {
      display: 'block',
      fontSize: ['calc((16 * 90) / 360)', '17px'],
      fontFamily: 'spoqaRegular',
      fontWeight: '400',
      lineHeight: ['calc((19 * 90vw) / 360)', '23px'],
    },
  },

  wrapperContent: (subEntry, isMobile) => ({
    display: 'flex',
    flexDirection: ['column', 'row'],
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: ['30px', '40px'],
    p: !isMobile || subEntry ? '0px' : '0px',
    maxWidth: ['100%', '100%'],
    width: '100%',
    padding: ['0 20px', '', '0px'],
  }),

  infoContainer: {
    width: '100%',
  },

  labelPadding: {
    mb: '12px',
  },

  imageContainer: {
    borderRadius: '8px',
    overflow: 'hidden',

    img: {
      objectFit: 'cover',
      aspectRatio: '11 / 7',
      cursor: 'pointer',
      borderRadius: '8px',
      width: '100%',
    },
  },

  wrapperSubEntryDesktop: (twoEntries) => ({
    maxWidth: ['100%', '440px'],
    img: {
      objectFit: 'cover',
      aspectRatio: twoEntries ? ['16 / 9', '11 / 7'] : ['16 / 9', '1 / 1'],
      cursor: 'pointer',
      borderRadius: '8px',
      width: '100%',
    },
  }),

  labelArrow: {
    display: 'flex',
    alignItems: 'center',
    p: {
      my: 0,
      mr: 2,
      color: 'tmBlue',
    },
    svg: {
      width: '11px',
      height: '19px',
      path: { fill: 'sideNavRed', transition: 'stroke 0.3s linear' },
    },
  },
  arrow: {
    display: 'inline',
    backgroundColor: 'tmRed',
    width: '19px',
    height: '19px',
    mask: 'url(/static/icons/left_arrow.svg) no-repeat center / contain',
  },
  contentTitle: {
    fontSize: ['calc((24 * 85vw) / 360)', '40px'],
    fontFamily: 'bold',
    lineHeight: ['calc((28.8 * 85vw) / 360)', '40px'],
    mb: ['12px', 3],
  },

  contentDescription: {
    fontSize: ['calc((16 * 100vw) / 360)', '22px'],
    fontFamily: 'spoqaRegular',
    fontWeight: '400',
    lineHeight: ['calc((19 * 100vw) / 360)', '30px'],
    pt: ['15px', '20px'],
    width: '100%',
  },
  contentButton: (index, aia, isMobile) => ({
    padding: '0px',
    border: 'none',
    backgroundColor: 'none',
    fontSize: ['calc((16 * 100vw) / 360)', '22px'],
    cursor: 'pointer',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    mt: !index || aia || !isMobile ? '12px' : '30px',
    mb: !aia && !index ? [''] : ['12px', '24px'],
    width: '100%',
    '&:hover': { bg: 'transparent' },
    fontFamily: 'spoqaMedium',
  }),

  wrapperCarrouselContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: ['flex-start'],
    justifyContent: 'flex-start',
    width: '100%',
    p: ['0 20px', '0 20px', '0 0'],
  },

  infoCarrouselContainer: {
    display: 'flex',
    flexDirection: ['column', 'column'],
    alignItems: ['flex-start', 'flex-start'],
    justifyContent: 'flex-start',
    width: '100%',
    maxWidth: ['100%', '100%'],
  },
  carrouselButton: {
    margin: 0,
    width: '50%',
    fontSize: ['calc((16 * 100vw) / 360)', '16px'],
    padding: '0px',
    border: 'none',
    backgroundColor: 'none',
    cursor: 'pointer',
    textAlign: 'left',
    display: 'flex',
    fontFamily: 'regular',
    '&:hover': { bg: 'transparent' },
    pt: '38px',
    '&:first-child': {
      pt: '5px',
    },
    pb: ['20px'],
  },

  secondaryButton: {
    backgroundColor: 'tmRed',
    color: 'tmWhite',
    border: 'none',
    padding: ['5px 20px', '10px'],
    textTransform: 'uppercase',
    fontFamily: 'bold',
    fontSize: ['calc((16 * 100vw) / 360)', '16px'],
    borderRadius: '8px',
    mt: '12px',
    mb: ['12x', ''],
    cursor: 'pointer',
  },

  curveOverlay: {
    backgroundColor: 'tmWhite',
    width: '100%',
    height: '20px',
    position: 'absolute',
    top: '-20px',
    borderTopLeftRadius: '20px',
    borderTopRightRadius: '20px',
    display: ['flex', 'none', 'none'],
  },
  carouselImage: (newToAIA) => ({
    width: newToAIA ? ['260px', '360px'] : ['200px', '288px'],
    height: newToAIA ? ['260px', '360px'] : ['200px', '288px'],
    borderRadius: '8px',
    objectFit: 'cover',
    cursor: 'pointer',
  }),
  imageWrapper: {
    display: 'flex',
    backgroundColor: 'tmBlue',
    height: '100%',
    position: 'relative',
    borderRadius: '8px',
    padding: '0px',
    margin: '0px',
  },
  modalImage: {
    display: 'block',
    margin: '0 auto',
  },
  overlay: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: ['70%', '60%'],
    opacity: 1,
    background: [
      'linear-gradient(rgba(15, 32, 75, 0) 0%, #0F204B 100%)',
      'linear-gradient(rgba(15, 32, 75, 0) 0%, #0F204B 100%)',
    ],
    zIndex: 1,
    borderRadius: '8px',
  },
  carrouselDescription: {
    position: 'absolute',
    bottom: 0,
    p: ['10px', '20px 30px 20px 20px'],
    zIndex: 1,
    color: 'tmWhite',
    textAlign: 'left',
    fontFamily: 'spoqaRegular',
    fontSize: ['12px', '20px'],
  },
  sliderWrap: (isNewToAIA, subEntry) => ({
    width: '100%',
    mx: ['0px', '0px', '0px'],
    '.swiper': {
      width: '100%',
      height: '100%',
    },
    '.swiper-wrapper': {
      justifyContent: 'flex-start',
      gap: ['0px', '0px'],
    },
    '.swiper-slide': {
      textAlign: 'center',
      fontSize: '18px',
      maxWidth: subEntry
        ? ['440px']
        : isNewToAIA
          ? ['100%', '440px']
          : ['200px', '288px'],
      /* the proportion was 212px on a 360px */
      width: subEntry && !isNewToAIA && ['calc((212vw * 100) / 360)', ''],
      aspectRatio: isNewToAIA && '16/9',
      height: isNewToAIA && '100%',
      background: 'transparent',
      display: 'flex',
      '-webkit-box-pack': 'center',
      '-ms-flex-pack': 'center',
      '-webkit-justify-content': 'center',
      justifyContent: 'center',
      '-webkit-box-align': 'center',
      '-ms-flex-align': 'center',
      '-webkit-align-items': 'center',
      alignItems: 'center',

      video: {
        '&::backdrop': {
          background: 'rgba(15, 32, 75, 0.9)',
        },
      },
    },

    '.swiper-slide img': {
      display: 'block',
      height: subEntry
        ? ['100%']
        : isNewToAIA
          ? ['100%', '288px']
          : ['200px', '288px'],
      width: subEntry
        ? ['100%']
        : isNewToAIA
          ? ['100%', '440px']
          : ['200px', '288px'],
      aspectRatio: subEntry && !isNewToAIA && '1',
      objectFit: 'cover',
      cursor: 'pointer',
      position: 'relative',
    },
    '.swiper-pagination': {
      position: 'relative',
      bottom: '0px',
      marginTop: ['12px', '37px', '39px'],
    },
    '.swiper-pagination-bullet': {
      width: ['8px', '10px', '15px'],
      height: ['8px', '10px', '15px'],
      background: '#0F204B',
      margin: ['0 8px !important', '0 15px !important', '0 15px !important'],
      borderRadius: '100%',
    },
    '.swiper-pagination-bullet-active': {
      background: '#D31345',
    },
  }),
  subTitle: {
    fontFamily: 'spoqaRegular',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: ['calc((16 * 85vw) / 360)', '16px'],
    lineHeight: ['calc((22 * 85vw) / 360)', '22px'],
  },
  title: (entry) => ({
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    fontFamily: 'spoqaBold',
    p: {
      my: 0,
      mr: 2,
      color: entry.specialTitle && 'tmRed',
    },
    svg: {
      width: '11px',
      height: '19px',
      path: { fill: 'sideNavRed', transition: 'stroke 0.3s linear' },
    },
  }),
  titlePadding: (isMobile, titleAligned) => ({
    px: !isMobile && !titleAligned && '0px',
  }),
  titleAligned: (titleAligned) => ({
    fontSize: titleAligned && ['', 'calc((11 * 85vw) / 360)', '28px'],
  }),
  titleSubImages: (containsSubImages) => ({
    mb: containsSubImages && -3,
  }),
}
