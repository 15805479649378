import redirectToPage from "./redirect"

const redirectAuth = (router, locale, action, session, loggedRoutes) => {
  if (loggedRoutes.includes(action)) {
    if (session) {
      redirectToPage(router, locale, '/coachingcorner')
    } else {
      redirectToPage(router, locale, '/login')
    }
  }
  else if (action) {
    redirectToPage(router, locale, action)
  }
}

export default redirectAuth