/* eslint-disable react/no-unknown-property */
/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react'
import ReactMarkdown from 'react-markdown'
import { useRouter } from 'next/router'
import { jsx, Box, Image, Heading } from 'theme-ui'
import styles from './styles'
import redirectToPage from '/lib/redirect'
const ContentBox = React.forwardRef(
  (
    {
      title,
      subtitle,
      description,
      cta,
      boxStyle,
      mobileCardsPerRow,
      onClickContent,
      icon,
      ...props
    },
    ref,
  ) => {
    const router = useRouter()
    const locale = router?.query?.locale

    return (
      <Box
        ref={ref}
        sx={{
          ...styles.container,
          ...styles.boxWidth(mobileCardsPerRow || 2),
          variant: `box.${boxStyle}`,
        }}
        onClick={() => {
          onClickContent()
          if (!router.asPath.includes('campalbum'))
            return redirectToPage(router, locale, cta?.action)
        }}
      >
        {props?.image && <Image src={props.image} /> /* background image */}
        {props?.overlay && (
          <div sx={{ ...styles.overlay, backgroundColor: props.overlay }}></div>
        )}
        {icon && <Image src={icon} sx={styles.icon} className="icon" />}
        <Box sx={styles.infoContainer}>
          <Heading
            as="h4"
            sx={{ ...styles.contentTitle, variant: `text.contentTitle` }}
          >
            {title} <span>{subtitle}</span>
          </Heading>
          <ReactMarkdown sx={styles.description} children={description} />
          <button sx={styles.contentButton}>{cta?.label}</button>
        </Box>
      </Box>
    )
  },
)

export default ContentBox
