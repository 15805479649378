import Box from "../../../Commons/Box"
import { Image } from "theme-ui"

import styles from "./styles"
import redirectToPage from "../../../../lib/redirect"
import { useRouter } from "next/router"

const PotmCardDesktop = ({ entryTitle, image, locale }) => {
  const router = useRouter()

  return (
    <Box sx={styles.container(entryTitle.hide, image.hide)} onClick={() => redirectToPage(router, locale, entryTitle.cta?.action)}>
      {!image.hide && (
        <Box sx={styles.imageContainer}>
          <Image src={image?.images[0]?.image} sx={styles.image} />
        </Box>
      )}
      {!entryTitle.hide && (
        <Box>
          <Box sx={styles.title}>
            <Box>
              <p>{entryTitle.text}</p> <span>{entryTitle.subTitle}</span>
            </Box>
            <Box sx={styles.subTitle}>
              {entryTitle.subtext}
            </Box>
            <Box sx={styles.description}>
              {entryTitle.description}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default PotmCardDesktop